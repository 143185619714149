<template>
  <div>

    <div class="card-toolbar mb-5">
      <router-link v-if="$can('purchases_invoices.create')" to="/purchases/purchases-invoices/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('purchases_invoices.add_purchases_invoice') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
          <div class="row mb-5">

            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="supplier_id">{{ $t('purchases_invoices.supplier') }}</label>
              <!-- <select name="" id="supplier_id" v-model="filters.supplier_id" class="custom-select" >
                  <option v-for="row in suppliers" :value="row.id" :key="row.id">
                      {{ row.full_name }}
                  </option>
              </select> -->
              <multiselect
                  v-model="supplier"
                  id="supplier_id"
                  :placeholder="$t('purchases_invoices.supplier')"
                  label="full_name"
                  track-by="id"
                  :options="suppliers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getSuppliers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="item_id">{{ $t('purchases_invoices.item') }}</label>
              <multiselect v-model="item"
                           :placeholder="$t('purchases_invoices.item')"
                           :label="'name'"
                           track-by="id"
                           :options="items"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :internal-search="false"
                           @search-change="getItems($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="item_id">{{ $t('purchases_invoices.inventory') }}</label>
              <multiselect v-model="inventory"
                           :placeholder="$t('purchases_invoices.inventory')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label>{{ $t('purchases_invoices.added_by') }}</label>
              <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select" >
                  <option v-for="row in users" :value="row.id" :key="row.id">
                      {{ row.name }}
                  </option>
              </select> -->
              <multiselect
                  v-model="user"
                  :placeholder="$t('added_by')"
                  label="name"
                  track-by="id" :options="users" :multiple="false" :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="purchase_code">{{ $t('purchases_invoices.purchase_code') }}</label>
              <input v-model="filters.purchase_code" type="text" id="purchase_code" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="from_date">{{ $t('purchases_invoices.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="to_date">{{ $t('purchases_invoices.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label>{{ $t('purchases_invoices.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <!-- <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('purchases_invoices.purchases_invoices')}}
            <span class="text-muted pt-2 font-size-sm d-block"
              >{{ $t(('purchases_invoices.purchases_invoices_section') )}}</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link  v-if="$can('purchases_invoices.create')" to="/purchases/purchases-invoices/create" class="btn btn-primary font-weight-bolder">
              <v-icon>mdi-plus</v-icon>{{ $t('purchases_invoices.add_purchases_invoice')}}
          </router-link>
        </div>
      </div> -->
      <div class="card-body">

        <!-- Filter -->
        <!-- <div> -->
        <!-- <div class="row justify-content-end p-4">
            <button @click="openAdvancedSearch" class="btn btn-secondary m-btn m-btn--icon w-auto" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div> -->

        <!-- </div> -->
        <!-- End Filter -->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="status" slot-scope="props">
            <button class="btn btn-secondary m-btn m-btn--icon w-auto" v-if="$can('purchases_invoices.change_status')" @click="showModal(props.row.id, props.row.status)" :key="props.row.id">
              {{ $t('open_stock_items.change_status') }}
              ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('purchases_invoices.update')" icon color="pink" :to="`/purchases/purchases-invoices/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('purchases_invoices.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

            <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/purchases/purchases-invoices/view/${props.row.id}`">
              <v-icon small class="mr-2">mdi-eye</v-icon>
            </v-btn>
            <b-dropdown id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
              <div class="dropdown-item d-flex justify-content-between" v-if="$can('purchases_invoices.printing_export_pdf')">
                <label>{{ $t('MENU.invoice_printing') }}</label>
                <div class="d-flex justify-content-between">
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/purchaseInvoice/${props.row.id}/print`">
                    <i class="fas fa-print"></i>
                  </router-link>
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/purchaseInvoice/${props.row.id}/pdf`">
                    <i class="fas fa-file-pdf"></i>
                  </router-link>
                </div>
              </div>
              <router-link class="dropdown-item" v-if="$can('purchases_invoices.payment_purchases_invoices') && props.row.status == 2 && props.row.is_end_received  == 0" :to="`/purchases/payment_purchase_invoices/create/${props.row.id}/purchase`">
                {{ $t('MENU.payment_purchase_invoices') }}
              </router-link>
              <a href="javascript:;" v-if="props.row.status == 2 && isSystemUser" @click="showStatusAfterConfirmModal(props.row.id, props.row.status)" class="dropdown-item">
                {{ $t('rollback_after_confirm') }}
              </a>
<!--              <router-link class="dropdown-item" v-if="props.row.receipt_document_id ? ($can('receipt_documents.update') && props.row.status == 2) : ($can('receipt_documents.create') && props.row.status == 2) "-->
<!--                           :to="(props.row.receipt_document_id ? `/purchases/receipt-documents/edit/${props.row.receipt_document_id}` : `/purchases/receipt-documents/create/${props.row.id}`)">-->
<!--                {{ $t('MENU.receipt_document') }}-->
<!--              </router-link>-->
              <router-link class="dropdown-item" v-if="$can('purchases_invoices.receipt_document') && props.row.status == 2 && props.row.is_end_received  == 0"
                           :to="`/purchases/receipt-documents/create/${props.row.id}`">
                {{ $t('MENU.receipt_document') }}
              </router-link>
              <a href="javascript:;" class="dropdown-item" v-if="($can('purchases_invoices.termination_of_receipt') && props.row.status == 2 && props.row.is_end_received  == 0)" @click="terminationOfReceipt(props.row)">
                {{ $t('purchases_invoices.termination_of_receipt') }}
              </a>
              <router-link class="dropdown-item" v-if="$can('purchases_invoices.purchase_refund') && props.row.status == 2 && props.row.count_receipt_document > 0" :to="`/purchases/purchases-refunds/create/${props.row.id}`">
                {{ $t('MENU.purchase_refund') }}
              </router-link>


<!--              <router-link v-if="$can('purchases_invoices.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchase-invoice/${props.row.id}/print`">-->
<!--                {{ $t('MENU.invoice_printing') }}-->
<!--              </router-link>-->
              <router-link v-if="$can('purchases_invoices.payment_details')" class="dropdown-item" :to="`/purchases/purchases-invoices/payment_details/${props.row.id}`">
                {{ $t('payment_details') }}
              </router-link>
<!--              <router-link  v-if="$can('purchases_invoices.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchase-invoice/${props.row.id}/pdf`">-->
<!--                {{ $t('export_invoice_pdf') }}-->
<!--              </router-link>-->
              <router-link  v-if="$can('purchases_invoices.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchase-invoice/${props.row.id}?is_show_price=0`">
                {{ $t('MENU.invoice_printing_without_price') }}
              </router-link>
            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
<!--    <b-modal ref="modal" hide-footer :title="$t('purchases_invoices.change_status')">-->

<!--      <div class="form-group row">-->
<!--        <div class="col-lg-12 mb-5">-->
<!--          <label>{{ $t('status') }}</label>-->
<!--          <select name="status" id="status" v-model="status_changed" class="custom-select">-->
<!--            <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="card-footer pl-0 pr-0 pb-0">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-6">-->
<!--            <button class="btn btn-primary mr-2" @click="changeStatus">{{ $t('save') }}</button>-->
<!--            <button class="btn btn-secondary" @click="hideModal">{{ $t('close') }}</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-modal>-->

    <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="ignore_status_from_list[statusId]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>

    <b-modal ref="StatusAfterConfirm" size="md" hide-footer :title="$t('rollback_after_confirm')">
      <status-after-confirm @hide-modal="hideStatusAfterConfirmModal()"
                          :status-list="status_list"
                          :url="routeStatusAfterConfirm"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="[2,3]"
                          @handling-data="getDataAfterChangeStatus">
      </status-after-confirm>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import StatusAfterConfirmForm from "@/view/content/forms/StatusAfterConfirmForm.vue";
import {mapGetters} from "vuex";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm.vue";

export default {
  name: "index-purchases-invoices",
  components: {'status-after-confirm': StatusAfterConfirmForm, 'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: 'purchases/purchases-invoices',
      routeChangeStatus: 'purchases/purchases-invoice/change-status',
      routeStatusAfterConfirm: 'purchases/purchases-invoice/process_treatment',
      mainRouteTerminationOfReceipt: 'purchases/purchases-invoice/termination-receipt',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        supplier_id: null,
        purchase_code: null,
        from_date: null,
        to_date: null,
        currency_id: null,
        user_id: null,
        status: null,
        inventory_id: null,
        item_id: null,
      },
      status_list: [],
      columns: ['#','title', 'create_date', 'purchase_date', 'business_name', 'purchase_code', 'total_amount_after_discount', 'invoice_total', 'currency_name', 'is_paid_status_name', 'total_paid_amount', 'inventory_name', 'status', 'actions'],
      data: [],
      currencies: [],
      suppliers: [],
      users: [],
      user: null,
      supplier: null,
      id_changed: null,
      status_changed: null,
      innerId: null,
      statusId: null,
      item: null,
      items: [],
      inventory: null,
      inventories: [],
      ignore_status_from_list:{
        2: [4],
      }
    }
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },

    item: function (val) {
      if (val) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
    inventory: function (val) {
      if (val) {
        this.filters.inventory_id = val.id;
      } else {
        this.filters.inventory_id = null;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function(){
      return this.currentUserPersonalInfo.is_system_user
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          title: that.$t('purchases_invoices.title'),
          create_date: that.$t('purchases_invoices.create_date'),
          purchase_date: that.$t('purchases_invoices.purchase_date'),
          business_name: that.$t('company.business_name'),
          purchase_code: that.$t('purchases_invoices.purchase_code'),
          total_amount_after_discount: that.$t('purchases_invoices.subtotal_before_tax'),
          invoice_total: that.$t('purchases_invoices.total'),
          currency_name: that.$t('purchases_invoices.currency'),
          is_paid_status_name: that.$t('purchases_invoices.is_paid_label'),
          total_paid_amount: that.$t('purchases_invoices.paid_amount_label'),
          inventory_name: that.$t('purchases_invoices.inventory'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_invoices")}]);
    this.getCurrencies();
    // this.getSuppliers();
    // this.getUsers();
    this.getStatus();
    this.getInventories();
  },
  methods: {
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.supplier_id = null;
      this.filters.purchase_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.filters.item_id = null;
      this.filters.inventory_id = null;
      this.user = null;
      this.supplier = null;
      this.item = null;
      this.inventory = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    terminationOfReceipt(item) {
      this.$confirmAlert(this.$t('are_you_sure_to_termination_of_receipt'), this.actionTerminationOfReceipt, item);
    },
    actionTerminationOfReceipt(item) {
      ApiService.patch(`${this.mainRouteTerminationOfReceipt}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getItems(filter = null) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/items", {params: {'name': filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus() {
      ApiService.patch(this.routeChangeStatus + '/' + this.id_changed, {
        status: this.status_changed,
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
        this.hideModal();
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    showModal(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.$refs['modal'].show();
      this.id_changed = id;
      this.status_changed = status;
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.id_changed = null;
      this.status_changed = null;
    },
    showStatusAfterConfirmModal(id, status) {
      this.$refs['StatusAfterConfirm'].show();
      this.innerId = id;
      this.statusId = status;
    },
    hideStatusAfterConfirmModal() {
      this.$refs['StatusAfterConfirm'].hide();
      this.innerId = null;
      this.statusId = null;
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
  },
};
</script>
